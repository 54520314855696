<script setup lang="ts">
import { twJoin } from 'tailwind-merge'

const props = withDefaults(defineProps<{
  btnClass?: string
}>(), {})

const { height } = useWindowSize()
const { y } = useWindowScroll({ behavior: 'smooth' })

const showScrollBtn = computed(() => y.value > (height.value / 2))
const btnClass = twJoin('fixed right-10 bottom-20 rounded-full flex items-center justify-center bg-gray-800/80 shadow', props.btnClass)
</script>

<template>
  <div>
    <teleport to="body" :show="showScrollBtn">
      <Transition name="fade-in-scale-up-transition" appear>
        <div v-if="showScrollBtn" :class="btnClass" @click="y = 0">
          <UButton size="xl" color="purple" variant="ghost" :ui="{ rounded: 'rounded-full' }" icon="i-mdi-format-vertical-align-top" />
        </div>
      </Transition>
    </teleport>
  </div>
</template>
