<template>
  <div id="main-content" class="flex size-full min-h-[calc(100dvh-var(--header-height))] flex-row">
    <LazySidebarAux />
    <main class="flex size-full min-h-[calc(100dvh-var(--header-height))] flex-grow flex-col [&:not(:has(.expand-main))]:px-3">
      <slot />
    </main>
    <BackTopBtn />
    <UNotifications />
    <LazySidebar />
    <KeepAlive>
      <LazyModalPlayer />
    </KeepAlive>
  </div>
</template>
